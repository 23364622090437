import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import Link from 'next/link';
import { nextAsset } from '../../../components/nextAsset';
import { BigCTA } from './BigCTA';

export const SmartlinksPageExplore: React.FC<{
  hideArtist?: boolean;
  hideRelease?: boolean;
  hidePlaylist?: boolean;
  subPageName?: string;
}> = ({ hideArtist, hideRelease, hidePlaylist, subPageName }) => {
  const intl = useIntl();
  const isShowTalkAbout = hidePlaylist || hideRelease || hideArtist;
  return (
    <div className="smartlinks-page-explore py-5 smartlink-bg-gradient">
      <Container className="py-5">
        {isShowTalkAbout ? (
          <h2 className="main-header text-center text-white pt-5 pb-3">
            {intl.formatMessage({
              id: 'sml.web.smartlinks.explore.title',
              defaultMessage: `Explore other Smart Links`
            })}
          </h2>
        ) : (
          <h2 className="main-header text-center text-white pt-5 pb-3">
            {intl.formatMessage({
              id: 'sml.web.explore.smartlinks.share-music.title',
              defaultMessage: `Get inspired with our demo Smart Links`
            })}
          </h2>
        )}
        <p className="main-header-subtitle text-center text-white">
          {intl.formatMessage({
            id: 'sml.web.explore.smartlinks.share-music.subtitle',
            defaultMessage: 'Whatever you’re promoting, we’ve got a Smart Link for that.'
          })}
        </p>

        <Row className="py-5 justify-content-center">
          {hideArtist ?? (
            <Col xs={12} lg={4} className="text-center text-lg-end py-3">
              <Link href="/artist" passHref>
                <a className="smartlinks-page-explore-box">
                  <img src={nextAsset('images/smartLinks/home/wave-promote-artist.svg')} alt="artist icon bg" />
                  <div className="smartlinks-page-explore-box-content">
                    <div>
                      <img src={nextAsset('images/smartLinks/home/promote-artist.svg')} alt="artist icon" />
                    </div>
                    <p>
                      {intl.formatMessage({
                        id: 'sml.web.smartlinks.upsell.benefits.artist.title',
                        defaultMessage: 'Artist Bio Link'
                      })}
                    </p>
                  </div>
                </a>
              </Link>
            </Col>
          )}
          {hideRelease ?? (
            <Col xs={12} lg={4} className="text-center py-3">
              <Link href="/release" passHref>
                <a className="smartlinks-page-explore-box">
                  <img src={nextAsset('images/smartLinks/home/wave-promote-release.svg')} alt="release link bg" />
                  <div className="smartlinks-page-explore-box-content">
                    <div>
                      <img src={nextAsset('images/smartLinks/home/promote-release.svg')} alt="release link icon" />
                    </div>
                    <p>
                      {intl.formatMessage({
                        id: 'sml.web.smartlinks.upsell.benefits.release.title',
                        defaultMessage: 'Release Link'
                      })}
                    </p>
                  </div>
                </a>
              </Link>
            </Col>
          )}
          {hidePlaylist ?? (
            <Col xs={12} lg={4} className="text-center text-lg-start py-3">
              <Link href="/playlist" passHref>
                <a className="smartlinks-page-explore-box">
                  <img src={nextAsset('images/smartLinks/home/wave-promote-playlist.svg')} alt="playlist link bg" />
                  <div className="smartlinks-page-explore-box-content">
                    <div>
                      <img src={nextAsset('images/smartLinks/home/promote-playlist.svg')} alt="playlist link icon" />
                    </div>
                    <p>
                      {intl.formatMessage({
                        id: 'sml.web.smartlinks.upsell.benefits.playlist.title',
                        defaultMessage: 'Playlist Link'
                      })}
                    </p>
                  </div>
                </a>
              </Link>
            </Col>
          )}
        </Row>
      </Container>

      {isShowTalkAbout && (
        <BigCTA source={`${subPageName} Explore`}>
          <h2 className="main-header text-white text-center pt-5 pb-3">
            {intl.formatMessage(
              { id: 'sml.web.bit-cta.create.title', defaultMessage: 'Create your own {subPageName}' },
              {
                subPageName
              }
            )}
          </h2>
        </BigCTA>
      )}
    </div>
  );
};
