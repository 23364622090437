import React from 'react';
import { Col, Container, Row } from 'reactstrap';

export enum TopComponentSize {
  Default,
  Narrow,
  FullWidth
}

function getXSForSize(size: TopComponentSize): number {
  switch (size) {
    default:
    case TopComponentSize.FullWidth:
    case TopComponentSize.Default:
      return 12;
    case TopComponentSize.Narrow:
      return 9;
  }
}

function getSMForSize(size: TopComponentSize): number {
  switch (size) {
    default:
    case TopComponentSize.FullWidth:
      return 12;
    case TopComponentSize.Default:
      return 10;
    case TopComponentSize.Narrow:
      return 9;
  }
}

export const TopComponent: React.FC<{
  size?: TopComponentSize;
  navbarComponent?: React.ReactNode;
}> = React.memo(({ children, size = TopComponentSize.Default, navbarComponent }) => {
  return (
    <div className="d-flex flex-column overflow-hidden" style={{ minHeight: '100%' }}>
      {navbarComponent}
      <Container className="flex-grow-1 d-flex flex-column justify-content-center">
        <Row>
          <Col xs={getXSForSize(size)} sm={getSMForSize(size)} className="mx-auto">
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
});
