import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import { PlaylistsSharingGetAccessButton } from '../playlistsSharing/PlaylistsSharingGetAccessButton';
import { nextAsset } from '../../../components/nextAsset';

export const BigCTA: React.FC<{ roundedTop?: boolean; source: string }> = React.memo(
  ({ children, roundedTop, source }) => {
    const intl = useIntl();
    return (
      <>
        <div className="position-relative w-100 py-5">
          {roundedTop && (
            <div className="rounded-top">
              <img
                src={nextAsset('images/smartLinks/curved-white-bg.svg')}
                className="w-100 position-absolute"
                alt="Curved Shape"
              />
            </div>
          )}
        </div>
        <Container className="py-5">
          {children ?? (
            <h2 className="main-header text-white text-center pt-5 pb-3">
              {intl.formatMessage({ id: 'sml.web.bit-cta.title', defaultMessage: 'Your music marketing starts here' })}
            </h2>
          )}
          <Row className="justify-content-center align-items-center">
            <Col className="mx-auto py-4">
              <PlaylistsSharingGetAccessButton className="text-white" isHighlighted source={source} />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
);
