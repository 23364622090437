import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { SignUpButton } from '../components/SignUpButton';

export const PlaylistsSharingGetAccessButton: React.FC<{
  source: string;
  demoURL?: string;
  className?: string;
  isHighlighted?: boolean;
  size?: 'lg' | 'xl';
}> = ({ children, className, isHighlighted, size = 'xl', source, demoURL }) => {
  const intl = useIntl();
  const router = useRouter();
  return (
    <div className={classNames('d-flex flex-column align-items-center py-3', className)}>
      <div className="d-flex flex-row gap-3">
        <SignUpButton
          color="secondary"
          size={size}
          className="my-1 btn-shadow-secondary"
          source={source}
          url={router.asPath}
        >
          {intl.formatMessage({
            id: 'sml.web.smart-links.get-started-for-free',
            defaultMessage: 'Get started for FREE'
          })}
        </SignUpButton>

        {demoURL !== undefined && (
          <Button
            href={demoURL}
            size="xl"
            color="white"
            className="text-secondary border-secondary my-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            {intl.formatMessage({
              id: 'sml.web.smart-links.see-demo',
              defaultMessage: 'See Demo'
            })}
          </Button>
        )}
      </div>
      {children}
      <p className={classNames('pt-3', { 'text-muted': !isHighlighted })}>
        <FormattedMessage id="sml.web.smart-links.no-cc-required" defaultMessage="No credit card required" />
      </p>
    </div>
  );
};
