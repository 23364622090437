import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Col, Container, Row } from 'reactstrap';
import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons/faCirclePlay';
import { faRectanglesMixed } from '@fortawesome/pro-solid-svg-icons/faRectanglesMixed';
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faChartMixed } from '@fortawesome/pro-solid-svg-icons/faChartMixed';
import { faIcons } from '@fortawesome/pro-solid-svg-icons/faIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartNetwork, faFileUser } from '@fortawesome/pro-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import Link from 'next/link';

const FeaturesLink: React.FC = () => (
  <Link href="/features">
    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
    <a />
  </Link>
);
export const SmartlinksFeatures: React.FC<{ showCTA?: boolean }> = ({ showCTA }) => {
  const intl = useIntl();

  return (
    <Container className="py-5 smartlinks-feature-artist">
      <Row className="py-5">
        <Col>
          <h2 className="main-header text-secondary text-center pt-5 pb-3">
            {intl.formatMessage({
              id: 'sml.web.smartlinks.artist.features.title',
              defaultMessage: 'Deep dive into Music24 features'
            })}
          </h2>
          <p className="main-header-subtitle text-center pb-4">
            {intl.formatMessage({
              id: 'sml.web.smartlinks.artist.features.subtitle',
              defaultMessage: 'We’ve got the tools to grow your influence:'
            })}
          </p>
        </Col>
      </Row>
      <Row className="align-items-stretch">
        <Col xs={12} md={6} className="py-3">
          <div className="smartlinks-feature-artist-box">
            <FontAwesomeIcon icon={faCirclePlay} size="2x" className="text-primary" />
            <div>
              <h5 className="">
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.embed',
                  defaultMessage: 'Embed player'
                })}
              </h5>
              <span className="text-start">
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.embed.description',
                  defaultMessage: 'Share a universal music player directly on your page.'
                })}
              </span>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className="py-3">
          <div className="smartlinks-feature-artist-box">
            <FontAwesomeIcon icon={faRectanglesMixed} size="2x" className="text-secondary" />
            <div>
              <h5>
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.landing',
                  defaultMessage: 'Landing page'
                })}
              </h5>
              <p className="text-start">
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.landing.description',
                  defaultMessage: 'Create visually stunning pages that instantly hook listeners.'
                })}
              </p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className="py-3">
          <div className="smartlinks-feature-artist-box">
            <FontAwesomeIcon icon={faLink} size="2x" className="text-secondary" />
            <div>
              <h5>
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.branded',
                  defaultMessage: 'Branded URLs'
                })}
              </h5>
              <p className="text-start">
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.branded.description',
                  defaultMessage: 'Promote your music through customizable smart URLs.'
                })}
              </p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className="py-3">
          <div className="smartlinks-feature-artist-box">
            <FontAwesomeIcon icon={faChartMixed} size="2x" className="text-primary" />
            <div>
              <h5>
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.actionable',
                  defaultMessage: 'Actionable analytics'
                })}
              </h5>
              <p className="text-start">
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.actionable.description',
                  defaultMessage: 'Check your audience insights and measure your performance.'
                })}
              </p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className="py-3">
          <div className="smartlinks-feature-artist-box">
            <FontAwesomeIcon icon={faFileUser} size="2x" className="text-primary" />
            <div>
              <h5>
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.accounts',
                  defaultMessage: 'Multiple Accounts'
                })}
              </h5>
              <p className="text-start">
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.accounts.description',
                  defaultMessage: 'Manage multiple artist accounts under one license.'
                })}
              </p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className="py-3">
          <div className="smartlinks-feature-artist-box">
            <FontAwesomeIcon icon={faIcons} size="2x" className="text-secondary" />
            <div>
              <h5>
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.widgets',
                  defaultMessage: 'Widgets'
                })}
              </h5>
              <p className="text-start">
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.widgets.description',
                  defaultMessage: 'Choose from different widgets: links, images, videos and more.'
                })}
              </p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className="py-3">
          <div className="smartlinks-feature-artist-box">
            <FontAwesomeIcon icon={faChartNetwork} size="2x" className="text-primary" />
            <div>
              <h5>
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.templates',
                  defaultMessage: 'Design templates'
                })}
              </h5>
              <p className="text-start">
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.templates.description',
                  defaultMessage: 'Choose a Smart Link template that suits you.'
                })}
              </p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className="py-3">
          <div className="smartlinks-feature-artist-box">
            <FontAwesomeIcon icon={faFacebookSquare} size="2x" className="text-primary" />
            <div>
              <h5>
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.retargeting.templates',
                  defaultMessage: 'Retargeting'
                })}
              </h5>
              <p className="text-start">
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.artist.features.retargeting.description',
                  defaultMessage: 'Measure, optimize & retarget audiences for your ad campaigns.'
                })}
              </p>
            </div>
          </div>
        </Col>
      </Row>
      {showCTA && (
        <Row className="justify-content-center align-items-center pt-5">
          <Col className="text-center pt-5">
            <Button color="secondary" size="lg" className="my-1 btn-shadow-secondary" tag={FeaturesLink}>
              {intl.formatMessage({
                id: 'sml.web.smart-links.explore-smart-links',
                defaultMessage: 'Explore Smart Links'
              })}
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};
